/* You can add global styles to this file, and also import other style files */

body {
	background: #000;
	margin: 0;
	padding: 0;
	font-family: sans-serif;
}

.register-form {
	max-width: 630px;
	min-height: 100vh;
	background: #eb0c00;
	margin: 0 auto;

	.exide-logo {
		text-align: center;
		padding: 115px 0 0;
	}

	.form-components {
		max-width: 340px;
		margin: 0 auto;
		padding-top: 25px;

		label {
			visibility: hidden;
		}

		input,select {
			border-radius: 30px;
			background: #fff;
			border: 1px solid #baafcc;
			outline: none;
			padding: 10px 16px;
			font-size: 16px;
			width: 316px;

			&::placeholder {
			    color: #bfbfbf;
			    opacity: 1;
			}
			&::selection {
			    color: #fff;
			    background: #9985be;
			}
		}

		select {
			width: 350px;
		}

		button {
			width: 100%;
			padding: 10px 16px;
			font-size: 16px;
			border: 0;
			border-radius: 30px;
			color: #000;
			background: #fdc426;
			margin: 30px 0 20px;
      cursor: pointer;

			&:hover {
				background: #000;
				color: #fff;
			}

			&.btn-add-more-member {
				background: #fff !important;
				color: #000;

				&:hover {
					background: #000 !important;
					color: #fff !important;
				}
			}
		}
    
    .welcome-text {
      width: 370px;
      font-size: 1.70rem;
      text-align: center;
      padding: 30px 0;
      
      h3 {
        &.title-no-weight {
          font-weight: 100;
          margin: 0;
        }
        
        &.title-bold {
          font-weight: 700;
          margin: 0;
        }
      }
    }
    
    .welcome-buttons {
      padding-top: 30px;
      
      button {
        margin: 5px 0;
      }
    }

		.store-members {
			.member-form {
				padding: 15px 0;
				border: 1px solid #fff;
				border-width: 1px 0 0;
				margin-top: 30px;
			}
		}

		.form-error {
			color: #fff;
			font-size: 16px;
			text-align: center;
		}

		.go-back {
			color: #fff;
			padding: 5px 0;
			font-size: 16px;
			text-align: center;

			a {
				color: #fff;
        cursor: pointer;
			}
		}

		.footer-text {
			text-align: center;
			color: #fff;
			padding: 20px 0;
			font-size: 14px;

			a {
				color: #fff;
			}
		}
	}
}